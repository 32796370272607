import api from "api";
import React, { useEffect, useState } from "react";
import { ProductType } from "types";
import { useParams } from "react-router-dom";
import ProductShort from "components/product-short";
import { useIntersectionObserver } from "hooks/IntersectionObserverInit";
import { useRef } from "react";

function Category() {
    const params = useParams<any>();
    const [isLoading, setIsloading] = useState<boolean>(true);
    const [take, setTake] = useState<number>(30);
    const [skip, setSkip] = useState<number>(0);
    const [products, setProducts] = useState<ProductType[]>([]);
    const ref = useRef<HTMLDivElement | null>(null);
    const entry = useIntersectionObserver(ref, {});
    const isVisible = !!entry?.isIntersecting;

    const getProductByCategory = async () => {
        var data = await api.products.getByCategory(params.categoryId, take, skip);
        setIsloading(false);
        setProducts(data);
    };

    const getMore = async () => {
        var newState = [...products];
        var newSkip = skip + take;
        var newItems = await api.products.getByCategory(params.categoryId, take, newSkip);
        setSkip(newSkip);
        newState = newState.concat(newItems);
        setProducts(newState);
    };

    useEffect(() => {
        if (isLoading === false) {
            if (isVisible === true) {
                getMore();
            }
        }
    }, [isVisible]);

    useEffect(() => {
        getProductByCategory();
    }, [params.categoryId]);

    return (
        <>
            <head>
                <title>{params.CategoryDescription.replace("_", / /g)} product list - Growthgensolutions</title>
                <meta name="description" content={"Growthgensolutions - " + params.CategoryDescription.replace("_", / /g) + " product list"} />
            </head>

            <div className="category">
                <div className="category-header my-3">{params.CategoryDescription.replace("_", / /g)}</div>
                <div className="products">
                    {products.map((p) => (
                        <ProductShort product={p} />
                    ))}
                    <div ref={ref}></div>
                </div>
            </div>
        </>
    );
}

export default Category;
