import React from "react";
import "./product-cart-item.scss";
import { CartType, ProductType } from "types";
import { RootState } from "types";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers";
import "./product-cart-item.scss";

interface props {
    productCount: CartType;
}

function ProductCartItem({ productCount }: props) {
    const dispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.cart);

    const removeItemById = () => {
        var newState = [] as ProductType[];
        const index = cart.findIndex((item) => item.id === productCount.product.id);
        cart.map((x: ProductType) => {
            newState.push(x);
        });
        newState.splice(index, 1);
        dispatch(actions.cart.set(newState));
    };

    const addToCart = () => {
        var newCart = [...cart] as ProductType[];
        newCart.push(productCount.product);
        dispatch(actions.cart.set(newCart));
    };

    const removeAllItemById = () => {
        var newState = [] as ProductType[];
        cart.map((x: ProductType) => {
            if (x.id !== productCount.product.id) {
                newState.push(x);
            }
        });
        dispatch(actions.cart.set(newState));
    };

    const getPrice = () => {
        return (Math.round(productCount.product.price * productCount.amount * 100) / 100).toFixed(2);
    };

    return (
        <div>
            <div className="product">
                <div className="card">
                    <div className="row">
                        <div className="col-2">
                            <div className="img-container  itemImg flex-shrink-0">
                                <img
                                    className="card-img-top img-fluid"
                                    height={200}
                                    src={`https://www.growthgensolutions.co.za${productCount.product.imgFullLocation}`}
                                    alt="Card image cap"
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/img/img_notFound.jpg";
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-9">
                            <div>
                                <p>{productCount.product.longDescription}</p>
                                <p>
                                    <b>{productCount.product.productCode}</b>
                                </p>
                                <div className="add-remove-icons">
                                    <i className="fa-lg fa fa-minus-circle hand" onClick={removeItemById}></i>
                                    <span className="mx-1 no-select">{productCount.amount}</span>
                                    <i className="fa-lg fa fa-plus-circle hand" onClick={addToCart}></i>
                                </div>
                            </div>
                        </div>
                        <div className="col-1 price-section">
                            <div className="price">R {getPrice()}</div>
                            <div>
                                <button className="btn btn-danger" onClick={() => removeAllItemById()}>
                                    Remove
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductCartItem;
