import api from "api";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductType, RootState } from "types";
import "./product.scss";
import ProductShort from "components/product-short";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "redux/reducers";

function Product() {
    const params = useParams<any>();
    const dispatch = useDispatch();
    const cart = useSelector((state: RootState) => state.cart);
    const [product, setProduct] = useState<ProductType>({
        compatibleWith: " ",
        estimatedPricePerCopy: 0,
        id: 0,
        idCategory: 0,
        imgFullLocation: "",
        imgTumbLocation: "",
        isDeleted: false,
        isVisable: true,
        longDescription: "",
        price: 0,
        productCode: "",
        searchCombenations: "",
        shortDescription: "",
    } as ProductType);
    const [relatedProduct, setRelatedProduct] = useState<ProductType[]>([] as ProductType[]);

    const getProduct = async () => {
        var product = await api.products.getByProductCode(params.productCode);
        var relatedProductData = await api.products.getRelatedProducts(params.productCode);
        setProduct(product);
        console.log(relatedProductData);
        setRelatedProduct(relatedProductData);
        console.log(product);
    };

    const removeItemById = () => {
        var newState = [] as ProductType[];
        const index = cart.findIndex((item) => item.id === product.id);
        cart.map((x: ProductType) => {
            newState.push(x);
        });

        newState.splice(index, 1);
        dispatch(actions.cart.set(newState));
    };

    const addToCart = () => {
        var newCart = [...cart] as ProductType[];
        newCart.push(product);
        dispatch(actions.cart.set(newCart));
    };

    function countOccurrencesById() {
        return cart.reduce((count, item) => (item.id === product.id ? count + 1 : count), 0);
    }

    useEffect(() => {
        getProduct();
    }, [params.productCode]);
    return (
        <>
            <head>
                <title>{`${product.productCode} - ${product.longDescription} - Growthgen Solutions`}</title>
                <meta name="description" content={product.longDescription} />
            </head>
            <div className="product">
                <div className="product-backbutton-grid">
                    <div className="back-button" onClick={() => (window.location.href = "/")}>
                        <i className="fa fa-arrow-left fa-lg"></i>
                    </div>
                    <div className="details">
                        <div className="container">
                            <div className="img-container  itemImg flex-shrink-0">
                                <img
                                    className="card-img-top img-fluid"
                                    height={300}
                                    src={`https://www.growthgensolutions.co.za${product.imgFullLocation}`}
                                    alt={product.longDescription}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = "/img/img_notFound.jpg";
                                    }}
                                />
                            </div>
                        </div>
                        <div className="description-container">
                            <table className="table table-hover table-responsive-sm SpesTable">
                                <tbody>
                                    <tr>
                                        <th>Description</th>
                                        <td id="description">{product.longDescription}</td>
                                    </tr>
                                    <tr>
                                        <th>Product number</th>
                                        <td id="Code">{product.productCode}</td>
                                    </tr>
                                    <tr>
                                        <th>Compatible with</th>
                                        <td id="CompatibleWith"> </td>
                                    </tr>
                                    <tr>
                                        <th>Page yeild</th>
                                        <td id="PageYeild">{product.estimatedPricePerCopy}</td>
                                    </tr>
                                    <tr>
                                        <th>Estimated price per copy</th>
                                        <td id="Eppc">R {product.estimatedPricePerCopy}</td>
                                    </tr>
                                    <tr className="tablePrice">
                                        <th>Price (Vat incl)</th>
                                        <td className="price green">R {product.price}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className="add-to-cart ">
                                {countOccurrencesById() === 0 && (
                                    <div onClick={addToCart} className="btn btn-outline-info my-btn">
                                        Add to cart
                                    </div>
                                )}
                                {countOccurrencesById() !== 0 && (
                                    <>
                                        <div className="add-remove-icons">
                                            <span className="info">This product is already in your cart</span>
                                            <i className="fa-lg fa fa-minus-circle hand" onClick={removeItemById}></i>
                                            <span className="mx-1 no-select">{countOccurrencesById()}</span>
                                            <i className="fa-lg fa fa-plus-circle hand" onClick={addToCart}></i>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="category-header mb-5 mt-2">
                        <i>You might also like</i>
                    </div>
                    <div className="related-product-container products mt-5">
                        {relatedProduct.map((x) => (
                            <ProductShort product={x} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Product;
