import React, { useEffect, useState } from "react";
import "./home.scss";
import { Button, Container, Dropdown, Form, Nav, NavDropdown, Navbar } from "react-bootstrap";
import MyNav from "components/nav/nav";
import SideNav from "components/side-nav";
import api from "api";
import { CategoryProductType } from "types";
import ProductShort from "components/product-short";
import { Link } from "react-router-dom";

function Home() {
    const [category, setCategory] = useState<CategoryProductType[]>([]);
    const [search, setSeatch] = useState<string>("");

    const doSearch = () => {
        if (search.trim() !== "") {
            localStorage.search = search;
            var searchValue = encodeURIComponent(search);
            window.location.href = "/search/" + searchValue;
        }
    };

    const getAllProducts = async () => {
        var data = await api.products.getByCategories();
        setCategory(data);
    };
    useEffect(() => {
        getAllProducts();
        window.localStorage.search = "";
    }, []);

    return (
        <>
            <head>
                <title>High-Quality Printer Cartridges for Every Brand - Growthgen Solutions</title>
                <meta name="description" content={"High-Quality Printer Cartridges for Every Brand - Growthgen Solutions"} />
            </head>
            <div id="home">
                <div className="welcom-section">
                    <div className="search-img">
                        <div className="links">
                            <Link to={"/about-us"}>Contact us</Link>
                        </div>
                        <h1 className="header my-3">Website under construction</h1>
                        <div className="search">
                            <Form className="d-flex">
                                <Form.Control type="search" placeholder="Find what you are looking for here" className="me-2" aria-label="Search" value={search} onChange={(e) => setSeatch(e.currentTarget.value)} />
                                <Button variant="primary" onClick={doSearch}>
                                    <i className="fas fa-search"></i>
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>

                {category.map((c) => (
                    <>
                        {c.products.length > 0 && (
                            <>
                                <div className="category-header my-3">{c.description}</div>
                                <div className="products">{c.products.length > 0 && c.products.map((p) => <ProductShort product={p} />)}</div>
                            </>
                        )}
                    </>
                ))}
            </div>
        </>
    );
}

export default Home;
