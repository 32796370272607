import React, { useEffect, useState } from "react";
import { RootState, CartType, CustomerOrderType } from "types";
import { useDispatch, useSelector } from "react-redux";
import "./orders.scss";
import api from "api";

function Orders() {
    const dispatch = useDispatch();
    const user = useSelector((state: RootState) => state.user);
    const [orders, setOrders] = useState<CustomerOrderType[]>([] as CustomerOrderType[]);

    const getOrders = async () => {
        var data = await api.order.getOrders(user.id);
        setOrders(data);
        console.log(data);
    };

    useEffect(() => {
        if (user !== null) {
            getOrders();
        }
    }, [user]);

    return (
        <>
            <head>
                <title>View your orders - Growthgen Solutions</title>
                <meta name="description" content={"View your orders - Growthgen Solutions"} />
            </head>
            <div>
                <div className="category-header my-3">Orders</div>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">No.</th>
                            <th scope="col">Date</th>
                            <th scope="col">Price</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orders.map((x) => (
                            <tr className="hand" onClick={() => window.open("https://dash.growthgensolutions.co.za/Invoice?orderId=" + x.id)}>
                                <th scope="row">{x.id}</th>
                                <td>{x.orderDate.split("T")[0]}</td>
                                <td>R {x.productPrice}</td>
                                {x.orderStatus === "Not paid" && (
                                    <td>
                                        <span className="badge rounded-pill bg-danger pillbadge">{x.orderStatus}</span>
                                    </td>
                                )}
                                {x.orderStatus === "Shiped" && (
                                    <td>
                                        <span className="badge rounded-pill bg-secondary pillbadge">{x.orderStatus}</span>
                                    </td>
                                )}
                                {x.orderStatus === "Paid" && (
                                    <td>
                                        <span className="badge rounded-pill bg-success pillbadge">{x.orderStatus}</span>
                                    </td>
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Orders;
