import { Route as DOMRoute } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Nav from "components/nav";
import MyNav from "components/nav/nav";
import SideNav from "components/side-nav";
import "./route.scss";
import { useEffect } from "react";
import api from "api";
import { LoginRequest, TokenUserId } from "types";
import { actions } from "redux/reducers";
import { RootState } from "types";

function Route({ path = "", isPublic = false, showSidenav = true, exact = false, ...props }) {
    const dispatch = useDispatch();
    //const user = useSelector((state: RootState) => state.user);
    const getUser = async () => {
        if (localStorage.AccessToken) {
            const user = await api.user.getUser({
                Token: localStorage.AccessToken,
                UserId: localStorage.UserId,
            } as TokenUserId);
            console.log(user);
            dispatch(actions.user.set(user));
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    if (isPublic === true) {
        return (
            <DOMRoute path={path} exact={exact}>
                <MyNav />
                <div className="side-nav-content-grid">
                    <SideNav />
                    <div className="content">
                        <props.component />
                    </div>
                </div>
            </DOMRoute>
        );
    } else {
        return (
            <DOMRoute path={path} exact={exact}>
                <MyNav />
                <div className="side-nav-content-grid">
                    <SideNav />
                    <div className="content">
                        <props.component />
                    </div>
                </div>
            </DOMRoute>
        );
    }
}

export default Route;
