import api from "api";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { UserType } from "types/user";

function Register() {
    const [registerUser, setRegisterUser] = useState<UserType>({
        id: 0,
        contackNumber: "",
        dateCreated: new Date() + "",
        email: "",
        idCustomerTier: 0,
        isAdminUser: false,
        isConfirmedCustomer: false,
        isDeleted: false,
        name: "",
        password: "",
    } as UserType);
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const validateEmail = (email: string) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const changeValue = (changeState: "firstName" | "mobileNumber" | "email" | "password" | "confirmPassword", value: any) => {
        var newState = { ...registerUser };
        if (changeState === "firstName") {
            newState.name = value;
        }
        if (changeState === "mobileNumber") {
            newState.contackNumber = value;
        }
        if (changeState === "email") {
            newState.email = value;
        }
        if (changeState === "password") {
            newState.password = value;
        }
        if (changeState === "confirmPassword") {
            setConfirmPassword(value);
        }
        setRegisterUser(newState);
    };

    const doRegister = async () => {
        if (registerUser.name.trim() === "") {
            toast.warning("Please enter a valid name");
            return;
        }
        if (registerUser.contackNumber.trim() === "") {
            toast.warning("Please enter a valid contact number");
            return;
        }
        if (!validateEmail(registerUser.email)) {
            toast.warning("Please enter a valid email");
            return;
        }
        if (registerUser.password.trim() === "" || confirmPassword.trim() === "") {
            toast.warning("Please enter a valid password and confirm password");
            return;
        }
        if (registerUser.password !== confirmPassword) {
            toast.warning("Passwords does not match");
            changeValue("password", "");
            setConfirmPassword("");
            return;
        }
        var data = await api.user.registerUser(registerUser);
        toast.success("User added");
        window.location.href = "/login";
    };
    return (
        <div>
            <div className="adress-form">
                <div className="container">
                    <div className="card mx-5 mt-3">
                        <div className="login-header px-3 py-1">
                            <div className="my-2">Register</div>
                        </div>
                        <div className="login-body p-5 pt-3">
                            <div className="form-item">
                                <Form.Label>First name:</Form.Label>
                                <Form.Control required type="text" placeholder="First name" value={registerUser.name} onChange={(e) => changeValue("firstName", e.currentTarget.value)} />
                            </div>
                            <div className="form-item">
                                <Form.Label>Mobile number:</Form.Label>
                                <Form.Control required type="text" placeholder="Mobile number" value={registerUser.contackNumber} onChange={(e) => changeValue("mobileNumber", e.currentTarget.value)} />
                            </div>
                            <div className="form-item">
                                <Form.Label>Email address:</Form.Label>
                                <Form.Control required type="email" placeholder="Email address" value={registerUser.email} onChange={(e) => changeValue("email", e.currentTarget.value)} />
                            </div>
                            <div className="form-item">
                                <Form.Label>Password:</Form.Label>
                                <Form.Control required type="password" placeholder="password" value={registerUser.password} onChange={(e) => changeValue("password", e.currentTarget.value)} />
                            </div>
                            <div className="form-item">
                                <Form.Label>Confirm password:</Form.Label>
                                <Form.Control required type="password" placeholder="Password" value={confirmPassword} onChange={(e) => changeValue("confirmPassword", e.currentTarget.value)} />

                                <div className="ad-btns mt-3">
                                    <button className="btn btn-primary mx-3" onClick={doRegister}>
                                        Register
                                    </button>
                                    <button className="btn btn-secondary">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;
