import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
    name: "cart",
    initialState: JSON.parse(localStorage.cart) ? JSON.parse(localStorage.cart) : [],
    reducers: {
        set: (state: any, action: { payload: any }) => {
            state = action.payload;
            localStorage.cart = JSON.stringify(state);
            return state;
        },
    },
});

export const cartActions = cartSlice.actions;

export default cartSlice.reducer;
