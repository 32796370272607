import api from "api";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "types";

function ResetPassword() {
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const user = useSelector((state: RootState) => state.user);

    const changeValue = (changeState: "password" | "confirmPassword", value: any) => {
        if (changeState === "password") {
            setPassword(value);
        }
        if (changeState === "confirmPassword") {
            setConfirmPassword(value);
        }
    };

    const doPasswordSave = async () => {
        if (password.trim() === "" || confirmPassword.trim() === "") {
            toast.warning("Please enter a valid password and confirm password");
            return;
        }
        if (password !== confirmPassword) {
            toast.warning("Passwords does not match");
            setPassword("");
            setConfirmPassword("");
            return;
        }
        var data = await api.user.retsetPassword(user.email);
        toast.success("Password set");
        window.location.href = "/";
    };
    return (
        <div className="adress-form">
            <div className="container">
                <div className="card mx-5 mt-3">
                    <div className="login-header px-3 py-1">
                        <div className="my-2">Forgot password</div>
                    </div>
                    <div className="login-body p-5 pt-3">
                        <div className="form-item">
                            <Form.Label>New Password:</Form.Label>
                            <Form.Control required type="password" placeholder="password" value={password} onChange={(e) => changeValue("password", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Confirm New Password:</Form.Label>
                            <Form.Control required type="password" placeholder="Password: " value={confirmPassword} onChange={(e) => changeValue("confirmPassword", e.currentTarget.value)} />

                            <div className="ad-btns mt-3">
                                <button className="btn btn-primary mx-3" onClick={doPasswordSave}>
                                    Save password
                                </button>
                                <button className="btn btn-secondary">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
