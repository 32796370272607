import React, { useState } from "react";
import "./login.scss";
import { Form } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { LoginRequest } from "types";
import { toast } from "react-toastify";
import api from "api";

function Login() {
    const history = useHistory();
    const [loginRequest, setLoginRequest] = useState<LoginRequest>({
        Email: "",
        Password: "",
    });

    const changeValue = (prop: "Email" | "Password", value: string) => {
        var newState = { ...loginRequest };
        if (prop === "Email") {
            newState.Email = value;
        }
        if (prop === "Password") {
            newState.Password = value;
        }
        setLoginRequest(newState);
    };

    const doLogin = async () => {
        if (loginRequest.Email.trim() === "") {
            toast.warning("Please enter a valid username");
            return;
        }
        if (loginRequest.Password.trim() === "") {
            toast.warning("Please enter a valid password");
            return;
        }
        var data = await api.user.login(loginRequest);
        if (data === undefined) {
            toast.warning("User name or password is incorrect");
        } else {
            localStorage.AccessToken = data.token;
            localStorage.UserId = data.userId;
            window.location.href = "/";
        }
    };

    return (
        <>
            <head>
                <title>Login - Growthgen Solutions</title>
                <meta name="description" content={"Login - Growthgen Solutions"} />
            </head>
            <div className="container">
                <div className="card mx-5 mt-3">
                    <div className="login-header px-3 py-1">
                        <div className="my-2">Login</div>
                    </div>
                    <div className="login-body p-5">
                        <div className="form-item">
                            <Form.Label>User name</Form.Label>
                            <Form.Control required type="text" placeholder="User name" value={loginRequest.Email} onChange={(e) => changeValue("Email", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <Form.Label>Password</Form.Label>
                            <Form.Control required type="password" placeholder="password" value={loginRequest.Password} onChange={(e) => changeValue("Password", e.currentTarget.value)} />
                        </div>
                        <div className="form-item">
                            <p className="forgot-password">
                                <Link to={"/forgot-password"}>Forgot your password?</Link>
                            </p>
                        </div>
                        <div className="form-item">
                            <button className="btn btn-primary" onClick={doLogin}>
                                Login
                            </button>
                        </div>
                        <div className="form-item">
                            <p className="register-text">
                                Don't have an account yet?{" "}
                                <span>
                                    <Link to={"/register"}>Register here.</Link>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
