import Route from "components/route";
import AboutUs from "pages/about-us";
import Cart from "pages/cart";
import Category from "pages/category";
import Home from "pages/home";
import Login from "pages/login/login";
import Orders from "pages/orders/orders";
import Product from "pages/product/product";
import Register from "pages/register";
import ResetPassword from "pages/reset-password/reset-password";
import Search from "pages/search";
import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
    if (localStorage.getItem("cart") === null) {
        localStorage.cart = [];
    }
    return (
        <div className="App">
            <ToastContainer />
            <Router>
                <Switch>
                    <Route path="/" component={Home} exact />
                    <Route path="/category/:CategoryDescription/:categoryId" component={Category} exact />
                    <Route path="/cart" component={Cart} exact />
                    <Route path="/login" component={Login} exact />
                    <Route path="/register" component={Register} exact />
                    <Route path="/orders" component={Orders} exact />
                    <Route path="/search/:searchString" component={Search} exact />
                    <Route path="/reset-password" component={ResetPassword} exact />
                    <Route path="/product/:productCode/:productDescription" component={Product} exact />
                    <Route path="/about-us" component={AboutUs} exact />
                </Switch>
            </Router>
        </div>
    );
}

export default App;
